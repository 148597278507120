import React, { useEffect } from 'react';
import { useIntl, IntlShape } from 'react-intl';
import { Column, Row } from 'react-table';
import { observer } from 'mobx-react-lite';
import { useStore } from 'stores/store-hooks';
import { Box, Button, Checkbox, Grid, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LinkIcon from '@material-ui/icons/Link';
import { KLTable } from 'components/tables';
import { Modal, ModalActions, ModalContent } from 'components/modal-dialog';
import { ILinkableFishBasin, LinkAndCopyAction } from 'models/link-and-copy';
import ClearIcon from "@material-ui/icons/Clear";
import basinStore from "../../../../stores/basin-store";
import {unlinkFishBasins} from "../../../../services/api";
import linkAndCopyStore from "../../../../stores/link-and-copy-store";
import growthFactorTableStore from "../../../../stores/growth-factor-table-store";
import toastStore from "../../../../stores/toast-store";

const chooseText = (
  intl: IntlShape,
  action: LinkAndCopyAction,
  msg: string
) => intl.formatMessage({
  id: action === LinkAndCopyAction.LINK ? ("Link" + msg) : ("Copy" + msg)
});

async function handleUnlink(row: ILinkableFishBasin) {
  const basinId = basinStore.selectedBasin?.id ? basinStore.selectedBasin.id : null
  if (basinId) {
    try {
      const response = await unlinkFishBasins(basinId)
      if (response.status === 200) {
        await growthFactorTableStore.loadTable()
        linkAndCopyStore.clearAll()
      } else {
        toastStore.setToast('ResetError')
      }
    } catch (e) {
      console.log(`Error occured: ${e}`)
    }
  } else {
    console.log(`No basin selected`)
  }


}

const getColumns = (
  intl: IntlShape,
  toggleTarget: (basin: ILinkableFishBasin) => any,
  isSelected: (basin: ILinkableFishBasin) => boolean,
) => [
  {
    id: "checkbox",
    Cell: ({ row }: { row: Row; }) => (
      <Checkbox
        checked={isSelected(row.original as ILinkableFishBasin)}
        onChange={() => toggleTarget(row.original as ILinkableFishBasin)}
        size="small"
        style={{ padding: 0 }}
      />
    ),
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "Facility" })}
      </Typography>
    ),
    accessor: "site.facility.name",
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "Site" })}
      </Typography>
    ),
    accessor: "site.name",
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "Basin" })}
      </Typography>
    ),
    accessor: "name",
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "Linked" })}
      </Typography>
    ),
    accessor: "linked",
    Cell: ({ value }: { value: boolean; }) => value ? <CheckIcon /> : null,
  },
  {
    Header: (
      <Typography variant="h6">
        {intl.formatMessage({ id: "ResetDefault" })}
      </Typography>
    ),
    accessor: "unlinkable",
    Cell: ({ value, row }: { value: boolean; row: any }) =>
      row.original.linked ? (
        <Button onClick={() => handleUnlink(row.original as ILinkableFishBasin)}>
          <ClearIcon />
        </Button>
      ) : null, // No icon if not linked
  }
] as Column<ILinkableFishBasin>[];

const LinkAndCopyDialog = observer(() => {
  const intl = useIntl();
  const linkAndCopyStore = useStore('linkAndCopyStore');
  const {
    action,
    clearAll,
    isSelected,
    linkableBasins,
    performAction,
    targetCount,
    toggleTarget,
  } = linkAndCopyStore;

  useEffect(() => {
    const loadData = async () => {
      await linkAndCopyStore.loadLinkableFishBasins();
    };
    loadData();
  }, [linkAndCopyStore]);

  return (
    <Modal handleClose={clearAll}>
      <ModalContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="span" variant="subtitle2">
              {chooseText(intl, action, "GrowthFactorTableLong")}
            </Typography>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Typography component="p" variant="body1">
                {chooseText(intl, action, "GrowthFactorTableText1")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" variant="body1">
                {chooseText(intl, action, "GrowthFactorTableText2")}
              </Typography>
            </Grid>
          </Grid>
          <KLTable
            columns={getColumns(intl, toggleTarget, isSelected)}
            data={linkableBasins}
          />
        </Grid>
      </ModalContent>
      <ModalActions>
        <Button color="primary" disabled={!targetCount} onClick={performAction}>
          {chooseText(intl, action, "GrowthFactorTableShort")}
        </Button>
        <Button color="primary" onClick={clearAll}>
          {intl.formatMessage({ id: "Cancel" })}
        </Button>
      </ModalActions>
    </Modal>
  );
});

export const GrowthFactorTableLinkAndCopyButtons = observer(
  ({ tableIsModified }: { tableIsModified: boolean }) => {
  const { isCompanyAdmin } = useStore('userStore');
  const { action, setActionCopy, setActionLink } = useStore('linkAndCopyStore');
  const intl = useIntl();

  return !isCompanyAdmin ? null : (
    <Box display="flex" m={2}>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            disabled={!tableIsModified}
            onClick={setActionLink}
            startIcon={<LinkIcon />}
            variant="outlined"
          >
            {intl.formatMessage({ id: 'LinkGrowthFactorTable' })}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!tableIsModified}
            onClick={setActionCopy}
            startIcon={<FileCopyOutlinedIcon />}
            variant="outlined"
          >
            {intl.formatMessage({ id: 'CopyGrowthFactorTable' })}
          </Button>
        </Grid>
      </Grid>
      {action === LinkAndCopyAction.NOTHING ? null : <LinkAndCopyDialog />}
    </Box>
  );
});
