import { makeAutoObservable, runInAction } from 'mobx';
import {
  IApiResponse,
  getIotDeviceInfo,
  getIotMeasurements,
  forcefeedStartCommand,
  emergencyStopCommand,
  getOperationById,
  updateCalibrationCommand,
  moveCameraCommand,
  zoomCameraCommand,
  stopAutomaticFeeding,
  startAutomaticFeeding,
  updateBatterySave,
} from '../services/api';
import {
  IDeviceInfoResponse,
  MEASUREMENT_TYPE,
  IMeasurementResult,
  IotCommandResponse,
  ICalibration, IBatterySave,
} from 'models/iot-devices';
import { IFishBasin } from 'models/fishbasin';
import basinStore from './basin-store';
import {ISimpleSite} from "../models/site";

class IotDeviceStore {
  constructor() {
    makeAutoObservable(this);
  }

  pollingErrorCount: number = 0;
  pollingTimer: number | undefined;
  deviceInfoResponses: IDeviceInfoResponse[] = [];
  pendingCommands: IotCommandResponse[] = [];
  measurementResults: {
    deviceId: string;
    measurementType: MEASUREMENT_TYPE;
    results: IMeasurementResult[];
  }[] = [];

  get hasPendingForceFeedCommand() {
    return !!this.pendingCommands.find((c) => {
      return !!c?.c8y_Command?.text?.includes('force_feed');
    });
  }

  get hasPendingCameraMovementCommand() {
    return !!this.pendingCommands.find((c) => {
      return !!c?.c8y_Command?.text?.includes('set_cam');
    });
  }

  getDeviceCalibration(basin: IFishBasin): number {
    if (basin) {
      const deviceInfo = this.deviceInfoResponses.find(
        (d) => d.id === basin.supportunit_id
      );
      if (deviceInfo && deviceInfo.deviceConfiguration && deviceInfo.deviceConfiguration.fCal) {
        return deviceInfo?.deviceConfiguration?.fCal;
      }
    }
    return 0;
  }

  get deviceCalibration() {
    const { selectedBasin } = basinStore;
    if (selectedBasin) {
      const deviceInfo = this.deviceInfoResponses.find(
        (d) => d.id === selectedBasin.supportunit_id
      );
      if (deviceInfo) {
        return deviceInfo?.deviceConfiguration?.fCal;
      }
    }
    return 0;
  }

  loadDeviceInfoWithUnitId = async (
    supportunit_id: string | undefined | null
  ): Promise<IApiResponse<IDeviceInfoResponse>> => {
    let data: IDeviceInfoResponse | null = null;
    if (supportunit_id) {
      try {
        const response = await getIotDeviceInfo(supportunit_id);
        /*if (response.data.deviceConfiguration === null) {
          toastStore.setToast('DeviceInfoFetchError', 'warning', undefined, true);
        }*/
        data = response.data;

        if (data?.deviceConfiguration)
          basinStore.autoFeedOn = data?.deviceConfiguration.autofeed === 1;
        basinStore.amountFedToday = data?.custom_props?.parsedStatus?.amount_fed ||
          this.measurementResults[0].results[0]?.value[1] || null;

        if (data.custom_props && data.custom_props.status) {
          // parse status result coming from integration service / iot device
          const { status } = data.custom_props;
          data.custom_props.parsedStatus = JSON.parse(status);
        }
        if (response.status === 200 || response.status === 201) {
          runInAction(() => {
            if (data) this.deviceInfoResponses.push(data);
          });
        }
        return {
          status: response.status,
          data,
        };
      } catch (error: any) {
        return {
          status: 400,
          data,
          errors: error,
        };
      }
    } else basinStore.amountFedToday = 0;

    return {
      status: 400,
      data,
    };
  };

  loadDeviceInfo = async (basin: IFishBasin) => {
    return this.loadDeviceInfoWithUnitId(basin ? basin.supportunit_id : null)
  }

  loadDeviceInfos = (site: ISimpleSite): Promise<IApiResponse<IDeviceInfoResponse>>[] => {
    const promises = site.fishbasins?.filter(basin => basin.supportunit_id)
      .map((basin: IFishBasin) => this.loadDeviceInfo(basin));
    return promises ? promises : [];
  }

  getBatterySaveEnabled = (basin: IFishBasin) => {
    const deviceInfo = this.deviceInfoResponses.find(
      (d) => d.id === basin.supportunit_id
    );
    if (deviceInfo?.deviceConfiguration?.deny_voltage && deviceInfo?.deviceConfiguration?.allow_voltage) {
      return deviceInfo?.version === "2.0"
        ? deviceInfo?.deviceConfiguration?.deny_voltage < deviceInfo?.deviceConfiguration?.allow_voltage
        : false;
    }
    return false;
  }

  getFeedAutomationStatus = (basin: IFishBasin) => {
    const deviceInfo = this.deviceInfoResponses.find(
      (d) => d.id === basin.supportunit_id
    );
    if (deviceInfo) {
      return deviceInfo?.version === "2.0"
        ? !!deviceInfo?.deviceConfiguration?.autofeed
        : deviceInfo?.custom_props?.parsedStatus?.status === 'automatic';
    }
    return false;
  };

  getDeviceInfo = (supportunit_id: string | null | undefined) => {
    if (supportunit_id) {
      return this.deviceInfoResponses.find((d) => d.id === supportunit_id) || null;
    }
    return null;
  };

  removeDeviceInfoById = (deviceId: string) => {
    runInAction(() => {
      this.deviceInfoResponses = this.deviceInfoResponses.filter(
        (d) => d.id === deviceId
      );
    });
  };

  loadMeasurements = async (
    deviceId: string,
    daysAgo: number,
    maxResults: number,
    type: MEASUREMENT_TYPE
  ): Promise<IApiResponse<IMeasurementResult[]>> => {
    const daysAgoMillis = daysAgo * 24 * 60 * 60 * 1000;
    const timestamp = new Date().getTime() - daysAgoMillis;
    try {
      const response = await getIotMeasurements(
        deviceId,
        timestamp,
        maxResults,
        type
      );
      const { data } = response;
      runInAction(() => {
        this.measurementResults.push({
          deviceId,
          measurementType: type,
          results: data,
        });
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error: any) {
      return {
        status: 400,
        data: null,
        errors: error,
      };
    }
  };

  startForceFeeding = async (
    basin: IFishBasin,
    duration: number
  ): Promise<IApiResponse<IotCommandResponse>> => {
    try {
      if (basin.supportunit_id) {
        const response = await forcefeedStartCommand(
          basin.supportunit_id,
          duration
        );
        runInAction(() => {
          this.pendingCommands.push(response.data);
        });
        this.startCommandPolling((duration / 2) * 1000);
        const responseData = {...response.data, basin: basin}
        return {
          status: response.status,
          data: responseData,
        };
      }
    } catch {}
    return {
      status: 400,
      data: null,
    };
  };

  emergencyStop = async (
    basin: IFishBasin
  ): Promise<IApiResponse<IotCommandResponse>> => {
    try {
      if (basin.supportunit_id) {
        const response = await emergencyStopCommand(basin.supportunit_id);
        runInAction(() => {
          this.pendingCommands.push(response.data);
        });
        this.startCommandPolling(3000);
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch {}
    return {
      status: 400,
      data: null,
    };
  };

  startFeeding = async (basin: IFishBasin | null): Promise<IApiResponse<IotCommandResponse>> => {
    if (basin?.supportunit_id) {
      try {
        const response = await startAutomaticFeeding(
          basin?.supportunit_id
        );
        return {
          status: response?.status,
          data: response.data,
        };
      } catch (error: any) {
        return {
          errors: error?.response?.data,
          status: error?.response?.status,
          data: null,
        };
      }
    }
    return {
      status: 400,
      data: null,
    };
  };


  stopFeeding = async (basin: IFishBasin | null): Promise<IApiResponse<IotCommandResponse>> => {
    if (basin?.supportunit_id) {
      try {
        const response = await stopAutomaticFeeding(
          basin?.supportunit_id
        );
        return {
          status: response?.status,
          data: response.data,
        };
      } catch (error: any) {
        return {
          errors: error?.response?.data,
          status: error?.response?.status,
          data: null,
        };
      }
    }
    return {
      status: 400,
      data: null,
    };
  };

  startCommandPolling = (initialDelayMillis: number) => {
    if (this.pollingTimer === undefined)
      this.pollingTimer = window.setTimeout(
        this.updatePendingCommands,
        initialDelayMillis
      );
  };

  updatePendingCommands = () => {
    if (this.pendingCommands.length && this.pollingErrorCount <= 5) {
      this.pendingCommands.forEach(async (command) => {
        if (command.id) {
          await this.updateCommand(command.deviceId, command.id);
        }
      });
      this.pollingTimer = window.setTimeout(this.updatePendingCommands, 10000);
    } else {
      this.pollingErrorCount = 0;
      this.pollingTimer = undefined;
    }
  };

  updateCommand = async (deviceId: string, operationId: string) => {
    try {
      const response = await getOperationById(deviceId, operationId);
      const { data } = response;
      if (data.status !== 'PENDING' && data.status !== 'EXECUTING') {
        runInAction(() => {
          this.pendingCommands = this.pendingCommands.filter(
            (c) => c.id !== operationId
          );
        });
      } else {
        const i = this.pendingCommands.findIndex((c) => c.id === operationId);
        if (i >= 0) {
          runInAction(() => {
            this.pendingCommands[i] = data;
          });
        }
      }
    } catch (error) {
      this.pollingErrorCount++;
    }
  };

  stopCommandPolling = () => {
    clearTimeout(this.pollingTimer);
    this.pollingTimer = undefined;
  };

  calibration = async (
    basin: IFishBasin,
    calibration: ICalibration
  ): Promise<IApiResponse<any>> => {

    try {
      if (basin.supportunit_id) {
        const iotResponse = await updateCalibrationCommand(
          basin.supportunit_id,
          calibration
        );

        runInAction(() => {
          const deviceInfo = this.deviceInfoResponses.find(
            (d) => d.id === basin.supportunit_id
          );
          if (deviceInfo && deviceInfo.deviceConfiguration) {
            deviceInfo.deviceConfiguration.fCal = calibration.calibration;
          }
        });
        return {
          status: iotResponse.status,
          data: iotResponse.data,
        };
      }
    } catch {}
    return {
      status: 400,
      data: null,
    };
  };


  isHw2 = (deviceId: string | undefined | null): boolean => {
    if(!deviceId) return false;
    const deviceInfo = this.deviceInfoResponses.find(
      (d) => d.id === deviceId
    );
    return deviceInfo?.version === "2.0";
  }

  updateBatterySave = async (deviceId: string | undefined | null, batterySaveThresholds: IBatterySave):
    Promise<IApiResponse<any>> => {
    try {

      const deviceInfo = this.deviceInfoResponses.find(
        (d) => d.id === deviceId
      );

      if (deviceId && deviceInfo?.version === "2.0") {
        const response = await updateBatterySave(deviceId, batterySaveThresholds);
        runInAction(() => {
          if (deviceInfo && deviceInfo.deviceConfiguration) {
            deviceInfo.deviceConfiguration.allow_voltage = batterySaveThresholds.allow;
            deviceInfo.deviceConfiguration.deny_voltage = batterySaveThresholds.deny;
          }
        });
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch {}
    return {
      status: 400,
      data: null,
    };
  };

  disableBatterySave = async (deviceId: string) => {
    const batterySaveThresholds = {allow: 0.0, deny: 0.0} as IBatterySave;
    return this.updateBatterySave(deviceId, batterySaveThresholds);
  };

  moveCameraLeft = (basin: IFishBasin) => this.moveCamera(basin, -1, 0);
  moveCameraRight = (basin: IFishBasin) => this.moveCamera(basin, 1, 0);
  moveCameraUp = (basin: IFishBasin) => this.moveCamera(basin, 0, -1);
  moveCameraDown = (basin: IFishBasin) => this.moveCamera(basin, 0, 1);

  moveCamera = async (basin: IFishBasin, az: number, elev: number) => {
    const sensitivity = 200;
    try {
      if (basin.supportunit_id) {
        const response = await moveCameraCommand(basin.supportunit_id, az*sensitivity, elev*sensitivity);
        runInAction(() => {
          this.pendingCommands.push(response.data);
        });
        this.startCommandPolling(5000);
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch {
      console.log('Error with moving camera');
    }
  };

  zoomCamera = async (basin: IFishBasin, zoomLevel: number) => {
    const zoomAmountPerLevel = 10;
    try {
      if (basin.supportunit_id) {
        const response = await zoomCameraCommand(basin.supportunit_id, zoomLevel*zoomAmountPerLevel);
        runInAction(() => {
          this.pendingCommands.push(response.data);
        });
        this.startCommandPolling(5000);
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch {
      console.log('Error with zooming camera');
    }
  };
}

export default new IotDeviceStore();
