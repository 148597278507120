import axios from 'axios';
import errorHandler from './error-handler';
import {ICredentials, UserModel} from 'models/user';
import {ICompany} from 'models/company';
import {INewSite, IOxygenValue, ISimpleSite, ITemperature} from 'models/site';
import {IFacility} from 'models/facility';
import {ChartOptions, IChartData} from 'models/chart-data';
import {
  IBasinSplitData,
  IBasinStartValues,
  IBhiCameraResponse,
  IFishBasin,
  IFishBasinAtu,
  IFishBasinEvent,
  IFishBasinRemovedFish,
  IFishType,
  ILastStatus,
  ISimpleFishBasin,
} from 'models/fishbasin';
import {IFeedStockEvent} from 'models/feedstock-event';
import {
  IBatterySave,
  ICalibration,
  IDeviceInfoResponse,
  IIotDeviceEvents,
  IMeasurementResult,
  IotCommandResponse,
  MEASUREMENT_TYPE,
} from 'models/iot-devices';
import {IFeedingSchedule, IScheduleUpdateRequest,} from 'models/feeding-schedule';
import {IAlarmSettings, IAlarmThreshold, IAlarmThresholdDto, INotificationSettings,} from 'models/alarm';
import {IFeedStockData, IFeedType, ISiloEntry} from 'models/silo';
import {IFishBasinComment} from 'models/comments';
import {IGrowthFactorTable} from 'models/growth-factor-table';
import {ICleaningResult, IFastingEvent, ILocalCleaningResult,} from 'models/harvesting';
import {ILinkableFishBasin} from 'models/link-and-copy';
import {IContactInfo, IDeliveryInfo, IOrder, IPackage, IProduct,} from 'models/orders';

export interface IApiResponse<T> {
  status: number;
  errors?: object;
  data: T | null;
}

export const API_PATHS = {
  user: {
    login: 'api/token/',
    currentUser: 'api/self/',
    raisiousers: '/api/raisiousers/',
    raisiousers_names: '/api/raisiousers-names',
    users: '/api/users/',
  },
  site: {
    simple: '/api/sites-simple/',
    sites: '/api/sites/',
    'sites-widget': '/api/sites-widget/',
    'biomass-widget': '/api/biomass-widget/',
  },
  facility: {
    facilities: '/api/facilities/',
  },
  basin: {
    fishbasins: '/api/fishbasins/',
    fishbasinsevents: '/api/fishbasinevents/',
    fishbasinstatuses: '/api/fishbasinstatuses/',
  },
  iot: '/api/iot/',
  fishtypes: '/api/fishtypes/',
  company: {companies: 'api/companies/'},
  feedtypes: '/api/feedtypes/',
  growthfactortables: '/api/growthfactortables/',
  comments: '/api/comments/',
  notifications: '/api/notifications/',
  oservice: {
    products: '/api/o-service/products/',
    orders: '/api/o-service/orders/',
  },
  feedstock: {
    feedstockevents: '/api/feedstockevents/',
  },
  password_reset: {
    request: '/api/password_reset/',
    confirm: '/api/password_reset/confirm/',
  },
};

const api = axios.create({
  baseURL: process.env.REACT_APP_REST_API_BASE_PATH,
  timeout: 30000,
});

// Register error handler
api.interceptors.response.use((response) => response, errorHandler);

api.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('auth_token');
  if (typeof window !== 'undefined' && window.localStorage && token) {
    config.headers.Authorization = `Token ${token}`;
    const language = window.localStorage.getItem('kasvuluotain_lang');
    if (language) config.headers['Accept-Language'] = language;
  }
  return config;
});

export const login = async (credentials: ICredentials) => {
  return api.post(API_PATHS.user.login, credentials);
};

export const logout = async () => {
  return api.delete(API_PATHS.user.login);
};

export const getCurrentUser = async () => {
  return api.get<UserModel>(API_PATHS.user.currentUser);
};

export const patchCurrentUser = async (data: any) => {
  return api.patch(API_PATHS.user.currentUser, data);
};

export const getCompanies = async () => {
  return api.get<ICompany[]>(API_PATHS.company.companies);
};

export const getCompanyById = async (id: number) => {
  return api.get<ICompany>(`${API_PATHS.company.companies}${id}/`);
};

export const createCompany = async (company: ICompany) => {
  return api.post<ICompany>(API_PATHS.company.companies, company);
};

export const updateCompany = async (id: number, company: ICompany) => {
  return api.patch<ICompany>(`${API_PATHS.company.companies}${id}/`, company);
};

export const deleteCompany = async (companyId: number) => {
  return api.delete(`${API_PATHS.company.companies}${companyId}/`);
};

export const deleteFacility = async (facilityId: number) => {
  return api.delete(`${API_PATHS.facility.facilities}${facilityId}/`);
};

export const fetchUsersById = async (companyId: number) => {
  return api.get(`${API_PATHS.company.companies}${companyId}/users/`);
};

export const fetchAllUsers = async () => {
  return api.get<UserModel[]>(`${API_PATHS.user.users}`);
};

export const fetchRaisioUsers = async () => {
  return api.get(API_PATHS.user.raisiousers);
};

export const fetchRaisioUsersNames = async () => {
  return api.get(API_PATHS.user.raisiousers_names);
};

export const deleteUser = async (userId: number) => {
  return api.delete(`${API_PATHS.user.users}${userId}/`);
};

export const createUserForCompany = async (
  companyId: number,
  user: UserModel
) => {
  return api.post(`${API_PATHS.company.companies}${companyId}/users/`, user);
};

export const patchUser = async (id: number, user: UserModel) => {
  return api.patch(`${API_PATHS.user.users}${id}/`, user);
};

export const createRaisioAdminUser = async (user: UserModel) => {
  return api.post(API_PATHS.user.raisiousers, user);
};

export const getSites = async () => {
  return api.get<ISimpleSite[]>(API_PATHS.site.sites);
}

export const getSiteById = async (id: number) => {
  return api.get<ISimpleSite>(`${API_PATHS.site.sites}${id}/`);
};

export const getSiteDetailsByFacilityId = async (facilityId: number) => {
  const response = await api.get<ISimpleSite[]>(
    `${API_PATHS.site.sites}facility/${facilityId}/`);
  if (!response.data) {
    response.data = []
  }
  return response;
};

export const getSitesByCompanyId = async (companyId: number) => {
  return api.get<ISimpleSite[]>(
    `${API_PATHS.company.companies}${companyId}/sites/`
  );
};

export const getFacilities = async () => {
  return api.get<IFacility[]>(API_PATHS.facility.facilities);
};

export const updateFacility = async (id: number, facility: IFacility) => {
  return api.patch<IFacility>(
    `${API_PATHS.facility.facilities}${id}/`,
    facility
  );
};

export const createFacilityForCompany = async (
  companyId: number,
  facility: IFacility
) => {
  return api.post(
    `${API_PATHS.company.companies}${companyId}/facilities/`,
    facility
  );
};

export const setBasinTempForSite = async (
  siteId: number,
  temperature: number
) => {
  return api.post<ITemperature>(
    `${API_PATHS.site.sites}${siteId}/watertemperatures/`,
    {
      temperature,
    }
  );
};

export const setBasinOxygenValueForSite = async (
  siteId: number,
  oxygenValue: number
) => {
  return api.post<IOxygenValue>(
    `${API_PATHS.site.sites}${siteId}/oxygenvalues/`,
    {
      oxygenvalue: oxygenValue,
    }
  );
};

export const createSiteForFacility = (facilityId: number, data: INewSite) => {
  return api.post<ISimpleSite>(
    `${API_PATHS.facility.facilities}${facilityId}/sites/`,
    data
  );
};

export const getSite = async (siteId: number) => {
  return api.get<ISimpleSite>(`${API_PATHS.site.sites}${siteId}/`);
};

export const updateSite = async (siteId: number, data: INewSite) => {
  return api.patch<ISimpleSite>(`${API_PATHS.site.sites}${siteId}/`, data);
};

export const deleteSite = async (siteId: number) => {
  return api.delete(`${API_PATHS.site.sites}${siteId}/`);
};

export const getFishTypes = async () => {
  return api.get<IFishType[]>(API_PATHS.fishtypes);
};

export const createBasinForSite = async (siteId: number, basin: IFishBasin) => {
  return api.post(`${API_PATHS.site.sites}${siteId}/fishbasins/`, basin);
};

export const updateBasinById = async (basinId: number, data: IFishBasin) => {
  return api.patch<IFishBasin>(
    `${API_PATHS.basin.fishbasins}${basinId}/`,
    data
  );
};

export const getFishBasin = async (id: string) => {
  return api.get<IFishBasin>(`${API_PATHS.basin.fishbasins}${id}/`);
};

export const getFishBasins = async () => {
  return api.get<IFishBasin[]>(API_PATHS.basin.fishbasins);
};

export const getSimpleActiveFishBasins = async () => {
  return api.get<ISimpleFishBasin[]>(
    `${API_PATHS.basin.fishbasins}?simple=true&active=true`
  );
};

export const deleteFishBasin = async (id: number) => {
  return api.delete(`${API_PATHS.basin.fishbasins}${id}/`);
};

export const archiveFishBasin = async (basinId: number) => {
  return api.patch<IFishBasin>(
    `${API_PATHS.basin.fishbasins}${basinId}/archive/`
  );
};

export const archiveAndCopyFishBasin = async (
  basinId: number,
  data: IBasinStartValues
) => {
  return api.patch<IFishBasin>(
    `${API_PATHS.basin.fishbasins}${basinId}/archive/`,
    {copy: true, amount: data.startamount, weight: data.startweight}
  );
};

export const unarchiveFishBasin = async (basinId: number, data: IFishBasin) => {
  return api.patch<IFishBasin>(
    `${API_PATHS.basin.fishbasins}${basinId}/restore/`,
    data
  );
};

export const getArchivedFishBasins = async () => {
  return api.get<ISimpleFishBasin[]>(
    `${API_PATHS.basin.fishbasins}?simple=true&archived=true`
  );
};

export const setCalculationStatus = async (
  basinId: number,
  isEnabled: boolean
) => {
  return api.patch<IFishBasin>(`${API_PATHS.basin.fishbasins}${basinId}/`, {
    feedingenabled: isEnabled,
  });
};

export const startAutomaticFeeding = async (unitID: string) => {
  return api.post<IotCommandResponse>(
    `${API_PATHS.iot}${unitID}/start_feeding/`
  );
};

export const stopAutomaticFeeding = async (unitID: string) => {
  return api.post<IotCommandResponse>(
    `${API_PATHS.iot}${unitID}/stop_feeding/`
  );
};

export const getIotDeviceInfo = async (unitID: string) => {
  return await api.get<IDeviceInfoResponse>(`${API_PATHS.iot}${unitID}`)
};

export const getIotMeasurements = async (
  unitID: string,
  timestamp: number,
  maxResults: number,
  type: MEASUREMENT_TYPE
) => {
  return api.get<IMeasurementResult[]>(
    `${API_PATHS.iot}${unitID}/measurements/?from=${timestamp}&maxResults=${maxResults}&type=${type}`
  );
};

export const forcefeedStartCommand = async (
  unitID: string,
  duration: number
) => {
  return api.post<IotCommandResponse>(
    `${API_PATHS.iot}${unitID}/force_feed/?time=${duration}`
  );
};

export const emergencyStopCommand = async (unitID: string) => {
  return api.post<IotCommandResponse>(
    `${API_PATHS.iot}${unitID}/emergency_stop/`
  );
};

export const moveCameraCommand = async (deviceId: string, az: number, elev: number) => {
  return api.post<any>(`${API_PATHS.iot}${deviceId}/set_camstep/?az=${az}&elev=${elev}`);
};

export const zoomCameraCommand = async (deviceId: string, zoom: number) => {
  return api.post<any>(`${API_PATHS.iot}${deviceId}/set_camzoom/?zoom=${zoom}`);
}

export const getOperationById = async (unitID: string, operationId: string) => {
  return api.get<IotCommandResponse>(
    `${API_PATHS.iot}${unitID}/operations/${operationId}/`
  );
};

export const getOperations = async (
  unitID: string,
  maxResults: number = 20
) => {
  return api.get<IotCommandResponse[]>(
    `${API_PATHS.iot}${unitID}/operations/?maxResults=${maxResults}`
  );
};

export const cancelOperation = async (unitId: string, operationId: string) => {
  return api.post<any>(
    `${API_PATHS.iot}${unitId}/operations/${operationId}/cancel/`
  );
};

export const updateCalibrationCommand = async (
  unitID: string,
  calibration: ICalibration
) => {
  return api.put<IotCommandResponse>(
    `${API_PATHS.iot}${unitID}/feeding-schedule/active/calibration-update/`,
    calibration,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const updateBatterySave = async (
  unitID: string,
  batterySave: IBatterySave
) => {
  return api.post<IotCommandResponse>(
    `${API_PATHS.iot}${unitID}/battery_save/`,
    batterySave,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getActiveFeedingSchedule = async (unitID: string) => {
  return api.get<IFeedingSchedule>(
    `${API_PATHS.iot}${unitID}/feeding-schedule/active/`
  );
};

export const putActiveFeedingSchedule = async (
  unitID: string,
  data: IScheduleUpdateRequest
) => {
  return api.put<IFeedingSchedule>(
    `${API_PATHS.iot}${unitID}/feeding-schedule/active/`,
    data
  );
};

export const getNotificationSettings = async (deviceIds: string[]) => {
  return api.get<INotificationSettings>(
    `${API_PATHS.notifications}settings?deviceIds=${deviceIds.join(",")}`
  )
}

//will overwrite previous settings
export const patchNotificationSettings = async (settings: INotificationSettings) => {
  return api.patch<any>(
    `${API_PATHS.notifications}settings`, settings
  )
}

export const getNotifications = async (unitIDs: string[]) => {
  return api.get<IAlarmSettings[]>(
    `${API_PATHS.notifications}rules?deviceIds=${unitIDs.join(",")}`
  );
};

export const updateNotifications = async (data: IAlarmSettings[]) => {
  return api.patch<IAlarmSettings[]>(`${API_PATHS.notifications}rules/`, data);
};

export const getThresholds = async (unitIDs: string[]) => {
  return api.get<IAlarmThreshold[]>(
    `${API_PATHS.notifications}thresholds?deviceIds=${unitIDs.join(",")}`
  );
};

export const updateThresholds = async (data: IAlarmThresholdDto[]) => {
  return api.patch<IAlarmThreshold[]>(
    `${API_PATHS.notifications}thresholds`,
    data
  );
};

export const getBasinEvents = async (id: number) => {
  return api.get<IFishBasinEvent[]>(
    `${API_PATHS.basin.fishbasins}${id}/events/`
  );
};

export const getBasinEventsByEventType = async (id: number, eventType: string) => {
  return api.get<IFishBasinEvent[]>(
    `${API_PATHS.basin.fishbasins}${id}/events/?type=${eventType}`
  )
}

export const postBasinExtraFeedEvent = async (basinId: number, weight: number) => {
  return api.post<IFishBasinComment>(
    `${API_PATHS.basin.fishbasinsevents}`,
    {fishbasin_id: basinId, eventtype: 'X', extrafeedweight: weight}
  );
};

export const getIotDeviceEvents = async (
  id: string,
  maxResults: number = 500
) => {
  return api.get<IIotDeviceEvents[]>(
    `${API_PATHS.iot}${id}/events/?maxResults=${maxResults}&revert=false`
  );
};

export const getIotDeviceAlarms = async (ids: string[]) => {
  return api.get<IIotDeviceEvents[]>(
    `${API_PATHS.notifications}alarms?deviceIds=${ids.join(",")}`
  );
};

export const getIotDeviceActiveAlarms = async (ids: string[]) => {
  return api.get<IIotDeviceEvents[]>(
    `${API_PATHS.notifications}active-alarms?deviceIds=${ids.join(",")}`
  );
};

export const clearAlarm = async (unitId: string, eventId: string) => {
  return api.patch<any>(
    `${API_PATHS.notifications}alarms/${eventId}/clear-for-device/${unitId}`
  );
};

export const getBasinGraphs = async (id: number) => {
  return api.get<IChartData[]>(
    `${API_PATHS.basin.fishbasins}${id}/fishbasinstatuses/?datatype=echarts`
  );
};

export const getBasinsGraphs = async (ids: number[]) => {
  const basinIdsParameter = '&basinIds=' + ids.join('&basinIds=');
  return api.get<IChartData[]>(
    `${API_PATHS.basin.fishbasinstatuses}compare/?datatype=echarts${basinIdsParameter}`
  );
};

export const getBasinBatteryGraphs = async (id: number) => {
  return api.get<ChartOptions[]>(
    `${API_PATHS.basin.fishbasins}${id}/batteryvalues/?datatype=echarts`
  );
};

export const getOxygenValuesData = async (siteId: number, basinId: number) => {
  return api.get<ChartOptions[]>(
    `${API_PATHS.site.sites}${siteId}/oxygenvalues/?basin=${basinId}&datatype=echarts`
  );
};

export const getWaterTemperatureData = async (
  siteId: number,
  basinId: number
) => {
  return api.get<ChartOptions[]>(
    `${API_PATHS.site.sites}${siteId}/watertemperatures/?basin=${basinId}&datatype=echarts`
  );
};

export const getSiloEntries = async (basinId: number) => {
  return api.get<ISiloEntry[]>(`${API_PATHS.basin.fishbasins}${basinId}/silo/`);
};

export const deleteFishbasinEvent = async (eventId: number) => {
  return api.delete(`${API_PATHS.basin.fishbasinsevents}${eventId}/`);
};

export const loadFeedTypes = async () => {
  return api.get<IFeedType[]>(`${API_PATHS.feedtypes}`);
};

export const updateFeedTypesCompanySpecific = async (data: IFeedType[]) => {
  return api.patch<IFeedType[]>(`${API_PATHS.feedtypes}add_and_remove_company_specific/`, data);
};

export const updateFeedTypes = async (data: IFeedType[]) => {
  return api.patch<IFeedType[]>(`${API_PATHS.feedtypes}add_and_remove/`, data);
};

export const createFeedStockEvent = async (
  basinId: number,
  data: IFeedStockData
) => {
  return api.post<any>(
    `${API_PATHS.basin.fishbasins}${basinId}/fishbasinevents/`,
    data
  );
};

export const postFeedStockEvent = async (data: {
  facility_id: number;
  feedtype_id: string;
  change: number;
  correction: boolean;
}) => {
  return api.post<IFeedStockEvent>(
    `${API_PATHS.feedstock.feedstockevents}`,
    data
  );
};

export const patchFeedStockEvent = async (
  basinId: number,
  eventId: number,
  data: IFeedStockData
) => {
  return api.patch<any>(
    `${API_PATHS.basin.fishbasins}${basinId}/fishbasinevents/${eventId}/`,
    data
  );
};

export const getGrowthFactorTableByBasin = async (basinId: number) => {
  return api.get<IGrowthFactorTable>(
    `${API_PATHS.basin.fishbasins}${basinId}/growthfactortable/`
  );
};

export const getGrowthFactorTableById = async (id: number) => {
  return api.get<IGrowthFactorTable>(`${API_PATHS.growthfactortables}${id}/`);
};

export const updateGrowthFactorTable = async (
  basinId: number,
  data: string
) => {
  return api.post<any>(
    `${API_PATHS.basin.fishbasins}${basinId}/growthfactortable/`,
    {values: data}
  );
};

export const getLinkableFishBasins = async (basinId: number) => {
  return api.get<ILinkableFishBasin[]>(
    `${API_PATHS.basin.fishbasins}${basinId}/linkable/`
  );
};

export const linkFishBasins = async (
  basinId: number,
  basinsToLink: number[]
) => {
  return api.post<any>(`${API_PATHS.basin.fishbasins}${basinId}/link/`, {
    target: basinsToLink,
  });
};

export const unlinkFishBasins = async (
  basinId: number,
) => {
  return api.post<any>(`${API_PATHS.basin.fishbasins}${basinId}/unlink/`, {
    target: basinId,
  });
};

export const copyToFishBasins = async (
  basinId: number,
  basinsToCopyTo: number[]
) => {
  return api.post<any>(`${API_PATHS.basin.fishbasins}${basinId}/copy/`, {
    target: basinsToCopyTo,
  });
};

export const getFishBasinComments = async (basinId: number) => {
  return api.get<IFishBasinComment[]>(
    `${API_PATHS.basin.fishbasins}${basinId}/comments/`
  );
};

export const postFishBasinComment = async (basinId: number, data: string) => {
  return api.post<IFishBasinComment>(
    `${API_PATHS.basin.fishbasins}${basinId}/comments/`,
    {eventtype: 'C', comment: data, alert: null}
  );
};

export const getCameraDetails = async (
  open: string = 'NA',
  close: string = 'NA',
  quality: string = 'high'
) => {
  return api.get<IBhiCameraResponse>(
    `${API_PATHS.basin.fishbasins}camera/?open=${open}&close=${close}&quality=${quality}`
  );
};

export const startCamera = async (deviceId: string, seconds: number) => {
  return api.post<any>(`${API_PATHS.iot}${deviceId}/camera_control/`, {
    power_state: 1,
    timeout: seconds,
  });
};

export const getSiteListWidgetData = async () => {
  return api.get<ISimpleSite[]>(`${API_PATHS.site['sites-widget']}`);
};

export const getDepthsBySensorType = async (
  deviceId: string,
  type: 'temperature' | 'oxygen'
) => {
  return api.get<string[]>(
    `${API_PATHS.iot}${deviceId}/measurements/depths/?type=${type}`
  );
};

export const getAllActiveComments = async () => {
  return api.get<IFishBasinComment[]>(`${API_PATHS.comments}?active`);
};

export const getSimplifiedBasinList = async () => {
  return api.get<IFishBasin[]>(
    `${API_PATHS.basin.fishbasins}?widget=true&active=true`
  );
};

export const getFastingEvents = async (basinId: number) => {
  return api.get<IFastingEvent[]>(
    `${API_PATHS.basin.fishbasins}${basinId}/fasting/`
  );
};

export const moveFishToFasting = async (
  basinId: number,
  fastingamount: number,
  details: string | null,
  timestamp: Date = new Date()
) => {
  return api.post<IFastingEvent>(
    `${API_PATHS.basin.fishbasins}${basinId}/fasting/`,
    {fastingamount: fastingamount, details: details, timestamp: timestamp.toISOString()}
  );
};

export const saveCleaningResult = async (
  eventId: number,
  data: ILocalCleaningResult
) => {
  return api.post<ICleaningResult>(
    `${API_PATHS.basin.fishbasinsevents}${eventId}/cleaningresults/`,
    data
  );
};

export const updateCleaningResult = async (
  eventId: number,
  data: ILocalCleaningResult,
  toEdit: number
) => {
  return api.patch<ICleaningResult>(
    `${API_PATHS.basin.fishbasinsevents}${eventId}/cleaningresults/${toEdit}/`,
    data
  );
};

export const markBatchProcessed = async (eventId: number) => {
  return api.patch<IFishBasinEvent>(
    `${API_PATHS.basin.fishbasinsevents}${eventId}/`,
    {processed: true}
  );
};

export const splitBasin = async (basinId: number, data: IBasinSplitData[]) => {
  return api.post<ISimpleFishBasin[]>(
    `${API_PATHS.basin.fishbasins}${basinId}/split/`,
    data
  );
};

export const getLastTemperatureByDepthFromIotDevice = async (
  deviceId: string,
  depth: string
) => {
  return api.get<{ value: number }>(
    `${API_PATHS.iot}${deviceId}/measurements/depths/1/?type=c8y_Oxygen_Temperature`
    // `${API_PATHS.iot}${deviceId}/measurements/depths/${depth}/?type=c8y_Oxygen_Temperature`
  );
};

export const getLastOxygenValueByDepthFromIotDevice = async (
  deviceId: string,
  depth: string
) => {
  return api.get<{ value: number }>(
    `${API_PATHS.iot}${deviceId}/measurements/depths/1?type=c8y_Oxygen`
    // `${API_PATHS.iot}${deviceId}/measurements/depths/${depth}?type=c8y_Oxygen`
  );
};

export const getProducts = async () => {
  return api.get<IProduct[]>(`${API_PATHS.oservice.products}`);
};

export const addPackages = async (
  data: { product: number; packages: IPackage[] }[]
) => {
  return api.patch<IProduct[]>(
    `${API_PATHS.oservice.products}add-packages/`,
    data
  );
};

export const addProducts = async (products: IProduct[]) => {
  return api.post<IProduct[]>(
    `${API_PATHS.oservice.products}add-products/`,
    products
  );
};

export const modifyProducts = async (products: IProduct[]) => {
  return api.patch<IProduct[]>(
    `${API_PATHS.oservice.products}modify-products/`,
    products
  );
};

export const deletePackages = async (packageIds: number[]) => {
  return api.patch<IProduct[]>(
    `${API_PATHS.oservice.products}delete-packages/`,
    packageIds
  );
};

export const postOrder = async (
  companyId: number,
  contactInfo: IContactInfo,
  deliveryInfo: IDeliveryInfo,
  products: { id: number; amount: number }[],
  creatingUserId: number,
) => {
  return api.post<IOrder>(
    `${API_PATHS.oservice.orders}/for-company/${companyId}`,
    {
      contact: contactInfo,
      delivery: deliveryInfo,
      products: products,
      creatingUserId: creatingUserId,
    }
  );
};

export const getOrders = async () => {
  return api.get<IOrder[]>(`${API_PATHS.oservice.orders}`);
};

export const markOrderDelivered = async (orderId: number) => {
  return api.post<IOrder[]>(
    `${API_PATHS.oservice.orders}delivery-status/${orderId}`,
    {status: 'DELIVERED'}
  );
};

export const getSeasonReport = async (basinId: number) => {
  return api.get<any>(
    `${API_PATHS.basin.fishbasins}${basinId}/season_report/`,
    {responseType: 'blob',}
  );
};

export const getGeneratedCsvReport = async (startDate: string, endDate: string) => {
  return await api.get<any>(
    `${API_PATHS.company.companies}generate_csv?start_date=${startDate}&end_date=${endDate}`,
    {
      responseType: 'blob',
      timeout: 600000
    }
  );
};

export const saveBasinOrder = async (basinIds: number[]) => {
  return api.patch<any>(`${API_PATHS.basin.fishbasins}basin_order/`, basinIds);
};

export const getFeedStockEvents = async (queryString: string = '') => {
  const path = `${API_PATHS.feedstock.feedstockevents}${queryString}`;
  return api.get<IFeedStockEvent[]>(path);
};

export const requestPasswordReset = async (email: string) => {
  return api.post<any>(API_PATHS.password_reset.request, {email});
};

export const confirmPasswordReset = async (token: string, password: string) => {
  return api.post<any>(API_PATHS.password_reset.confirm, {token, password});
};

export const getRemovedFishReportByCompanyId = async (companyId: number) => {
  return api.get<any>(`${API_PATHS.company.companies}${companyId}/removedfishreport/`);
}

export const getFishBasinStatusByDate = async (basinId: number, date: string) => {
  return api.get<ILastStatus[]>(`${API_PATHS.basin.fishbasins}${basinId}/fishbasinstatuses/?date=${date}`);
}

export const getFishBasinStatuses = async (basinId: number) => {
  return api.get<ILastStatus[]>(`${API_PATHS.basin.fishbasins}${basinId}/fishbasinstatuses/`);
}

export const clearFishBasinStatusHistory = async (basinId: number, startingFromDate: string) => {
  return api.post<ILastStatus[]>(`${API_PATHS.basin.fishbasins}${basinId}/fishbasinstatuses/clear_history/?startdate=${startingFromDate}`);
}

export const getFishBasinAtu = async (id: string) => {
  return api.get<IFishBasinAtu>(`${API_PATHS.basin.fishbasins}${id}/atu/`);
};

export const getFishBasinRemovedFish = async (id: string) => {
  return api.get<IFishBasinRemovedFish>(`${API_PATHS.basin.fishbasins}${id}/removed_fish/`);
};

export const postBasinAutomationToggleEvent = async (basinId: number, value: boolean) => {
  return api.post<IFishBasinComment>(
    `${API_PATHS.basin.fishbasinsevents}`,
    {fishbasin_id: basinId, eventtype: 'Y', details: String(value)}
  );
};

export const postBasinForceFeedingEvent = async (basinId: number, duration: number) => {
  return api.post<IFishBasinComment>(
    `${API_PATHS.basin.fishbasinsevents}`,
    {fishbasin_id: basinId, eventtype: 'Z', details: String(duration)}
  );
};

export const getCompanyFeedingScheduleProfiles = async (companyId: number) => {
  return api.get<IFeedingSchedule[]>(
    `${API_PATHS.iot}${companyId}/feeding-schedule/profiles/`
  );
};

export const saveCompanyFeedingScheduleProfile = async (
  companyId: number,
  data: IScheduleUpdateRequest
) => {
  return api.put<IFeedingSchedule>(
    `${API_PATHS.iot}${companyId}/feeding-schedule/profile`,
    data
  );
};

export const deleteCompanyFeedingScheduleProfile = async (feedingScheduleId: number) => {
  return api.put<IFeedingSchedule[]>(
    `${API_PATHS.iot}${feedingScheduleId}/feeding-schedule/delete-profile`
  );
};
