/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Grid, CircularProgress } from '@material-ui/core';
import styled from '@emotion/styled';
import { useStore } from 'stores/store-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { BasinDetails } from './gadgets/basin-details';
import { SiteDetails } from './gadgets/site-details';
import { FishSiloDetails } from './gadgets/fish-silo-details';
import { FeedPercentage } from './gadgets/feed-percentage';
import { BasinControlCalculation } from './gadgets/basin-control-calculation';
import { BasinControlAutomatic } from './gadgets/basin-control-automation';
import { TabAreaContainer } from './tab-area-container';
import { BasinArchivedNotice } from './gadgets/basin-archived-note';
import { BasinActionsFooter } from './basin-footer';
import { BasinFeedAmount } from './gadgets/basin-feed-amount';
import { APPBAR_HEIGHT } from '../../constants';

export const BasinView = observer(() => {
  const basinStore = useStore('basinStore');
  const graphStore = useStore('graphStore');
  const siloStore = useStore('siloStore');
  const iotStore = useStore('iotStore');
  const eventStore = useStore('eventStore');
  const [loadingInitialData, setLoadingInitialData] = useState(true);
  const { selectedBasin } = basinStore;
  const location = useLocation();
  const history = useHistory();

  let { id } = useParams<{ id: string }>();
  const [gadgetAreaRef, setNode] = useState<any>(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  const loadInitialData = useCallback(async () => {
    if (Number(id) !== selectedBasin?.id) {
      graphStore.resetLoadTimer();
      basinStore.reset();
      const response = await basinStore.loadBasin(id);
      if (response.status === 200 && response.data) {
        await iotStore.loadDeviceInfo(response.data);
        // If autoFeed is on, need to fetch fish gadget info that's under feeder automation tab
        if (basinStore.autoFeedOn && location.pathname.split('/')[location.pathname.split('/').length - 1] !== 'feeder-automation')
          history.push(location.pathname.split(id)[0] + id + '/feeder-automation');
        else basinStore.amountFedToday = 0;
      }
    }
    setLoadingInitialData(false);
  }, [basinStore, graphStore, id, iotStore, selectedBasin?.id, history, location.pathname]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData, location.pathname]);

  useEffect(() => {
    eventStore.loadEvents();
    selectedBasin && siloStore.loadSiloEntries();
  }, [selectedBasin, eventStore, siloStore])

  if (!selectedBasin || loadingInitialData) {
    return (
      <LoadingRoot>
        <CircularProgress size={50} />
      </LoadingRoot>
    );
  }

  return (
    <Root>
      <GadgetArea ref={ref}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <BasinArchivedNotice basin={selectedBasin} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <GadgetWrapper>
              <BasinDetails selectedBasin={selectedBasin} />
            </GadgetWrapper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
            <GadgetWrapper bottomGutter>
              <SiteDetails basin={selectedBasin} />
            </GadgetWrapper>
            <GadgetWrapper>
              <FishSiloDetails basin={selectedBasin} />
            </GadgetWrapper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Box
              display="flex"
              css={css`
                align-content: stretch;
                & > div {
                  // If automation widget is show, stretch widgets to fill the space
                  flex-grow: ${basinStore.hasIotAutomation ? '1' : '0'};
                }
              `}
            >
              <GadgetWrapper bottomGutter rightGutter>
                <BasinFeedAmount basin={selectedBasin} />
              </GadgetWrapper>
              <GadgetWrapper bottomGutter rightGutter>
                <BasinControlCalculation basin={selectedBasin} />
              </GadgetWrapper>
              {basinStore.hasIotAutomation && (
                <GadgetWrapper bottomGutter>
                  <BasinControlAutomatic basin={selectedBasin} />
                </GadgetWrapper>
              )}
            </Box>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <GadgetWrapper>
                <FeedPercentage basin={selectedBasin} siloEntries={siloStore.siloEntriesNoSummary} />
              </GadgetWrapper>
            </Grid>
          </Grid>
        </Grid>
      </GadgetArea>
      <TabAreaContainer
        gadgetAreaHeight={gadgetAreaRef?.clientHeight as number}
      />
      <BasinActionsFooter basin={selectedBasin} />
    </Root>
  );
});

/** Styled components */

const GadgetArea = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  margin-left: ${({ theme }) => theme.spacing(0)}px;
  padding-right: ${({ theme }) => theme.spacing(1)}px;
  width: auto;
`;

const Root = styled.div`
  min-width: 800px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-bottom: ${APPBAR_HEIGHT}px;
`;

const LoadingRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
`;

const GadgetWrapper = styled.div<{
  bottomGutter?: boolean;
  rightGutter?: boolean;
}>`
  background-color: ${({ theme, bottomGutter }) => theme.palette.common.white};
  border-radius: 5px;
  min-height: 85px;
  margin-bottom: ${({ bottomGutter }) => (bottomGutter ? '16px' : '0px')};
  margin-right: ${({ rightGutter }) => (rightGutter ? '16px' : '0px')};
`;
