import { ISimpleSite } from './site';
import {IDeviceInfoResponse} from "./iot-devices";

export enum POWER_STATE {
  POWER_SOURCE_BATTERY = 1,
  POWER_SOURCE_MAINS = 0,
}

export enum BASIN_TYPE {
  Round = 'R',
  Square = 'S',
}

export enum FEEDING_METHOD {
  Automatic = 'A',
  HandFeeding = 'H',
  Mixed = 'M',
}

export interface ICurrentFeedInfo {
  feed_percentage?: number;
  feed_amount?: number;
  raisio_recommended_percentage?: number;
}

export interface ILastStatus {
  eventdate?: string;
  biomass_user?: number;
  biomass_table?: number;
  averageweight_user?: number;
  averageweight_table?: number;
  feedamount_user?: number;
  feedamount_table?: number;
  cumulativefeed_user?: number;
  cumulativefeed_table?: number;
  feedpercentage_user?: number;
  feedpercentage_table?: number;
  timestamp?: string;
  currentamount?: number;
  found?: boolean;
}

export interface IFishType {
  url: string;
  id: number;
  name: string;
  translated_name: string;
}

export interface IBasinDimensions {
  width?: number;
  length?: number;
  depth?: number;
  diameter?: number;
}

export interface IFishBasin {
  url?: string;
  id: number;
  name: string;
  site: ISimpleSite;
  basintype?: string;
  dimensions?: IBasinDimensions;
  feedingmethod?: string;
  lastfeedtype?: number;
  feedingenabled?: boolean;
  lastpercentofrecommended?: number;
  currentfeedinfo?: ICurrentFeedInfo;
  laststatus?: ILastStatus;
  fishtype?: IFishType;
  fishtype_id?: number;
  brand?: string;
  startweight?: string;
  startamount?: number;
  purchaseprice?: any;
  currentamount?: number;
  currentbiomasses?: number[];
  currentaverageweights?: number[];
  fastingweights?: number[];
  biomass_m3?: number;
  biomass?: number;
  latitude?: any;
  longitude?: any;
  alert?: boolean;
  original?: any;
  created?: Date;
  deleted?: boolean;
  deleted_timestamp?: Date;
  fishbasinevents?: string;
  supportunit_id?: string;
  video_stream_url?: string;
  video_camera_name?: string;
  custom_ordering_index: number;
  ancestors?: number[];
  currentbiomass_user?: string;
  fixedfeedingpercent?: number;
  usefixedfeedingpercent?: boolean;
  deviceInfo?: IDeviceInfoResponse | null;
  device_voltage?: number | null;
  temperature?: number;
  oxygenvalue?: number;
  extra_feed_weight?: number;
  growingseason_end?: string;
  growingseason_start?: string;
  activeAlarms?: boolean;
  activeAlarmsCount?: number;
  autofeed?: boolean;
}

export type BasinEventTypes =
  | 'F'
  | 'P'
  | 'E'
  | 'S'
  | 'C'
  | 'T'
  | 'W'
  | 'R'
  | 'A'
  | 'H'
  | 'D'
  | 'M'
  | 'X'
  | 'K' // K as in Kasvuluotain
  | 'Y'
  | 'Z';

interface IEventValues {
  averageweight: number;
  biomass: number;
  fishamountcorrection?: number;
  reason: string;
  amount?: number;
  value: string;
  feederamount?: number;
  feedname: string;
  deadamount?: number;
  extrafeedweight?: number;
  username?: string;
}

export interface IFishBasinEvent {
  timestamp: string;
  css?: string;
  eventtype: string;
  userevent: boolean;
  text?: string;
  values?: IEventValues;
}

export interface IBhiCameraResponse {
  requested: string;
  'Select Video': number;
  [key: string]: string | number;
}

export interface ISimpleFishBasin {
  url?: string;
  id: number;
  name: string;
  site: ISimpleSite;
  basintype?: string;
  dimensions?: IBasinDimensions;
  feedingmethod?: string;
  lastfeedtype?: number;
  feedingenabled?: boolean;
  lastpercentofrecommended?: number;
  currentfeedinfo?: ICurrentFeedInfo;
  laststatus?: ILastStatus;
  fishtype?: IFishType;
  fishtype_id?: number;
  brand?: string;
  startweight?: string;
  startamount?: number;
  purchaseprice?: any;
  currentamount?: number;
  currentbiomasses?: number[];
  currentaverageweights?: number[];
  biomass_m3?: number;
  biomass?: number;
  latitude?: any;
  longitude?: any;
  alert?: boolean;
  original?: any;
  created?: Date;
  deleted?: boolean;
  deleted_timestamp?: Date;
  supportunit_id?: string;
  video_stream_url?: string;
  custom_ordering_index: number;
  ancestors?: number[];
  deviceInfo?: IDeviceInfoResponse | null;
  device_voltage?: any;
  temperature?: number;
  oxygenvalue?: number;
}

export interface IBasinSplitData {
  name: string;
  startamount: number;
  startweight: number;
  basintype: string;
  feedingmethod: string;
  biomass: number;
  dimensions: IBasinDimensions;
}

export interface IBasinStartValues {
  startamount: number;
  startweight: number;
}

export interface IBasinArchiveData {
  id: number;
  startvalues?: IBasinStartValues;
}

export interface IFishBasinAtu {
  fishbasin_id: number;
  growingseason_start: Date;
  growingseason_end: Date;
  accumulated_thermal_unit: number;
}

export interface IFishBasinRemovedFish {
  total_averageweight: number;
  total_fishamountcorrection: number;
}

export interface INewCumulativeAmount {
  new_cumulative_amount: number;
  autofeed?: boolean;
}
