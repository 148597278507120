interface ILinkableFishBasinFacility {
  url: string;
  id: number;
  name: string;
}

interface ILinkableFishBasinSite {
  url: string;
  id: number;
  name: string;
  facility: ILinkableFishBasinFacility;
}

export interface ILinkableFishBasin {
  id: number;
  name: string;
  site: ILinkableFishBasinSite;
  linked: boolean;
  unlinkable?: boolean;
}

export enum LinkAndCopyAction {
  LINK,
  COPY,
  NOTHING,
}
